<template>
  <div>
    <div style="display: inline-block; width: 100%; height: 100%;">
      <div class="child-top" style="padding-top: 0;">
        <div class="input-from">
          <el-select
            class="last-child"
            clearable
            placeholder="请选择状态"
            v-model="roleState"
            @change="stateChange()"
          >
            <el-option :label="'启用'" :value="1"></el-option>
            <el-option :label="'停用'" :value="0"></el-option>
          </el-select>

          <div>
            <el-input
              @keyup.enter.native="getData(true)"
              class="left-child-input left-child-input-content"
              clearable
              placeholder="请输入角色名称"
              style="width: 200px; float: left;"
              suffix-icon="iconfont iconsousuo"
              v-model="input4"
            >
            </el-input>
            <el-button class="searchBtn" @click="getData(true)" round>搜索</el-button>
          </div>
        </div>

        <div class="operation-button">
          <el-button
            @click="addDataLog"
            class="new_btn"
            operation-button
            round
            :disabled="$store.getters.permissionsStr('新增')"
          >
            <span>新增</span>
          </el-button>
          <el-button
            @click="delMoreData"
            class="del_btn"
            round
            :disabled="$store.getters.permissionsStr('删除')"
          >
            <span>删除</span>
          </el-button>
        </div>
      </div>

      <div class="content-flex-pages" style="background: #fff;">
          <!-- :data="!$store.getters.permissionsStr('列表') ? tableData : []" -->

        <el-table
          :empty-text="tableData.length ? '' : '暂无数据'"
          :data="!$store.getters.permissionsStr('列表') ? tableData : []"
          @selection-change="tableSelectionChange"
          class="content-test-table"
          style="width: 100%;"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column label="序号" type="index" width="45">
            <template slot-scope="scope">
              {{ (currentPage - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="角色名称"
            prop="name"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="包含账号数"
            prop="account_quantity"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="备注"
            prop="demo"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="创建人"
            prop="create_by"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="状态"
            prop="status"
            show-overflow-tooltip
            width="80"
          >
            <template slot-scope="scope">
              <el-switch
                v-show="scope.row.uuid"
                active-color="#0055E9"
                :active-value="1"
                :inactive-value="0"
                :width="35"
                @change="ChangeState(scope.row)"
                inactive-color="#ccc"
                v-model="scope.row.status"
                :disabled="$store.getters.permissionsStr('启/停用')"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                class="right20"
                style="color: #0055E9;"
                size="medium"
                type="text"
                @click="editorData(scope.row, scope.$index)"
                :disabled="$store.getters.permissionsStr('编辑')"
                v-if="scope.row.uuid"
                >编辑
              </el-button>
              <el-button
                @click="delTableData(scope.row)"
                class="redColor"
                size="medium"
                style="color: #FD3258;"
                type="text"
                :disabled="$store.getters.permissionsStr('删除')"
                v-if="scope.row.uuid"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="10"
          :total="tableTotal"
          @current-change="handleCurrentChange"
          class="table-pagination"
          layout="total, prev, pager, next , jumper"
        ></el-pagination>
      </div>
    </div>

    <!--  新增-->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogTableVisible"
      :before-close="cancel"
      :title="!dialogTableVisibleType ? '新增角色' : '编辑角色'"
      width="50%"
      center=""
    >
      <div class="ModifyDoalog">
        <!-- <p>{{ !dialogTableVisibleType ? '新增' : '编辑' }}角色</p> -->
        <el-form
          :label-position="'left'"
          :model="form"
          :rules="rules"
          label-width="50px"
          ref="form"
        >
        <div class="form_inRact">
          <el-form-item label="名称" placeholder="请输入名称" prop="js_name" style=" width: 45%;">
            <el-input v-model="form.js_name"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="js_remark" style="width: 45%;">
            <el-input v-model="form.js_remark"></el-input>
          </el-form-item>
        </div>
        </el-form>
          <!-- <el-input
            v-model="form.accountNum"
            @keyup.enter.native="accounSous(true)"
            :disabled="false"
            suffix-icon="iconfont icon-sousuo-icon"
          ></el-input> -->
          <div class="newTable">
            <div style="width: 45%; float: left;border: 1px solid #ccc;">
              <span style="margin: 0px 10px 0 20px;">
                选择账号
              </span>
              <el-input
                class="left-child-input left-child-input-content"
                clearable
                placeholder="请输入关键字"
                style="width: 200px;margin-bottom: 15px;margin-top: 10px;"
                suffix-icon="iconfont iconsousuo"
                v-model="search"
                @keyup.enter.native="accounSous(true)"
              >
              </el-input>
              <el-table
                :data="newTableData"
                @select="rowChange"
                @select-all="rowChangeAll"
                class="content-test-table new_table"
                v-loading="addtableLoading"
                style="width: 100%;"
                ref="dataTable"
                height="250"
                :header-cell-style="{ background: '#F4F6F9' }"
              >
                <el-table-column
                  type="selection"
                  width="45"
                  :selectable="selectable"
                ></el-table-column>
                <el-table-column
                  label="账号"
                  prop="account"
                  width="135"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="姓名"
                  prop="name"
                  width="135"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>

            <div style="width: 50%;marin-left: 50px;float:right; border:1px solid #ccc;">
              <span style="display:block; margin: 15px 10px 28px 20px;">
                已选账号
              </span>
              <el-table
                :data="selectTableData"
                class="content-test-table new_table"
                style="width: 100%;"
                height="250"
                v-loading="addtableLoading"
                :header-cell-style="{ background: '#F4F6F9' }"
              >
                <el-table-column label="序号" type="index" width="45">
                  <template slot-scope="scope">
                    {{ (currentPage - 1) * 10 + scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  width="110"
                  label="账号"
                  prop="account"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="姓名"
                  width="110"
                  prop="name"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <i class="iconfont iconshanchu-moren" @click="selectClick(scope.$index)"></i>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="form-bottom-button">
            <el-button @click="save" type="primary" v-no-more-click class="save_btn" round
              >保存</el-button
            >
            <el-button @click="cancel" class="cancel_btn" round>取消</el-button>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      input4: '',
      roleState: '',
      tableDataSelectArr: [], // 多选
      newTableDataSelectArr: [], // 新增 多选
      newRoleArr: [],
      tableData: [{uuid:0}], // 表格
      form: {
        id: '',
        js_name: '',
        js_remark: '',
        accountNum: '',
        js_uuids: '',
      },
      rules: {
        js_name: { ...this.$rules.noEmpty, message: '名称不能为空' },
      },
      currentPage: 1,
      tableTotal: 5,
      tableLoading: false,
      dialogTableVisible: false,
      dialogTableVisibleType: false,
      newTableData: [
        {
          account: '1',
          name: '11'
        },
        {
          account: '2',
          name: '22'
        }
      ],
      selectTableData: [],
      mrTableData: [],
      accountNum: '',
      hasSelectList: [2, 4],
      newData: [],
      addtableLoading: false,
    };
  },
  created() {},
  watch: {},
  computed: {

  },
  mounted() {
    this.create();
  },
  methods: {
    create(page) {
      this.tableLoading = true;
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      // 列表数据
      this.$axios
        .post(
          '/p/role/lists',
          this.$qs({
            page: this.currentPage,
            name: this.input4,
            status: this.roleState !== '' ? this.roleState : '3',
          })
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.lists;
            this.tableTotal = res.data.data.total;
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectClick(index) {
      this.$nextTick(() => {
        var clear = this.selectTableData.splice(index, 1)[0]
        this.newTableData.forEach((item, index) => {
          if(item.uuid == clear.uuid) {
            this.$refs.dataTable.toggleRowSelection(item, false)
          }
        })
      })
    },
    // 新增
    addDataLog() {
      this.addtableLoading = true;
      this.dialogTableVisible = true;
      this.dialogTableVisibleType = false;
      this.accountAlldata();
    },
    // 获取所有账户
    accountAlldata() {
      this.newTableData = [];
      this.form.id = '';
      this.$axios
        .post('/p/user/getAll')
        .then((res) => {
          if (res.data.code === 0) {
            for (var val in res.data.data) {
              this.newTableData.push(res.data.data[val]);
            }
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.addtableLoading = false;
        })
        .catch((error) => {});
    },
    checkAlldatta() {
      this.newTableData = [];
      this.$axios
        .post(
          '/p/user/getAll',
          this.$qs({
            role_uuid: this.form.id,
          })
        )
        .then((res) => {
          if (res.data.code === 0) {
            for (var val in res.data.data) {
              this.newTableData.push(res.data.data[val]);
            }
            this.$nextTick(() => {
              this.newTableData.forEach((item) => {
                if (item.checked) {
                  this.selectTableData.push(item)
                  this.$refs.dataTable.toggleRowSelection(item, true);
                }
              });
            });
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.addtableLoading = false;
        })
        .catch((error) => {});
    },
    selectable(row, index) {
      if (row.can_check == '0') {
        return false;
      } else {
        return true;
      }
    },
    cancel() {
      this.dialogTableVisible = false;
      this.$refs.form.resetFields();
                  this.selectTableData = []
    },
    // 编辑
    editorData(row, index) {
      this.addtableLoading = true;
      this.dialogTableVisible = true;
      this.dialogTableVisibleType = true;

      this.$axios
        .post(
          'p/role/read',
          this.$qs({
            uuid: row.uuid,
          })
        )
        .then((res) => {
          if (res.data.code === 0) {
            var data = res.data.data;
            this.form.id = data.uuid;
            this.form.js_name = data.name;
            this.form.js_remark = data.demo;
            this.checkAlldatta();
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
            this.addtableLoading = false;
          }
        })
        .catch((error) => {});
    },
    // 单行删除
    delTableData(row) {
      this.$confirm('确定删除数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.$axios
          .post(
            '/p/role/del',
            this.$qs({
              uuids: row.uuid,
            })
          )
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: res.data.message,
                type: 'success',
              });
              let num = this.getNum(this.tableTotal - 1);
              if (this.currentPage > num) {
                this.currentPage = num;
              }
              this.create(this.currentPage);
            } else {
              this.$utils.message({
                type: 'error',
                message: res.data.message,
              });
            }
          })
          .catch((error) => {});
      });
    },
    // 多行删除
    delMoreData(row) {
      var touristIds = '';
      // 选择的账号
      for (var i = 0; i < this.tableDataSelectArr.length; i++) {
        touristIds += `${this.tableDataSelectArr[i].uuid},`;
      }
      touristIds = touristIds.substring(0, touristIds.length - 1);
      this.$confirm('确定删除数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.$axios
          .post(
            '/p/role/del',
            this.$qs({
              uuids: touristIds,
            })
          )
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: res.data.message,
                type: 'success',
              });
              let num = this.getNum(touristIds.length - 1);
              this.currentPage = num;
              this.create(num);
            } else {
              this.$utils.message({
                type: 'error',
                message: res.data.message,
              });
            }
          })
          .catch((error) => {});
      });
    },
    // 选择状态  1 启用 2 停用  空
    stateChange() {
      this.create();
    },
    // 账户搜索
    accounSous() {
      this.$axios
        .post(
          '/p/user/getAll',
          this.$qs({
            keyword: this.search,
          })
        )
        .then((res) => {
          this.newTableData = [];
          if (res.data.code === 0) {
            for (var val in res.data.data) {
              this.newTableData.push(res.data.data[val]);
            }

            this.$nextTick(() => {
              this.newTableData.forEach((item) => {
                this.selectTableData.forEach((itm, idx) => {
                  if(itm.uuid == item.uuid) {
                    this.$refs.dataTable.toggleRowSelection(item, true);
                  }
                })
              });
            });
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
          this.addtableLoading = false;
        })
        .catch((error) => {});
    },
    // 列表搜索
    getData() {
      this.create();
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.create(val);
      this.tableLoading = true;
    },
    // 表格状态 0 禁用 1 启用
    ChangeState(state) {
      this.$axios
        .post(
          '/p/role/able',
          this.$qs({
            uuid: state.uuid,
          })
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.create(this.currentPage);
            this.$utils.message({
              type: 'success',
              message: res.data.message,
            });
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    rowChange(selection, row) {
      console.log(row);
      var typeLink = false, idx = -1
      this.selectTableData && this.selectTableData.forEach((item, index) => {
        if(item.uuid == row.uuid) {
          typeLink = true
          idx = index
        }
      })

      setTimeout(() => {
        if(!typeLink) {
          this.selectTableData.push(row)
        } else {
          this.selectTableData.splice(idx, 1)
        }
      }, 100);

    },

    rowChangeAll(selection) {
      if(selection.length) {
        selection.forEach(item => {
          let index = JSON.stringify(this.selectTableData).indexOf(JSON.stringify(item))
          if(index == -1) {
            this.selectTableData.push(item)
          }
        })
      } else {
        this.selectTableData = []
      }
    },

    // 多选
    tableSelectionChange(val) {
      this.tableDataSelectArr = val;
    },
    // 新增 多选
    newTableSelectionChange(val) {
      this.selectTableData = val
      this.newtTableDataSelectArr = val;
      var touristIds = '';
      // 选择的账号
      for (var i = 0; i < this.newtTableDataSelectArr.length; i++) {
        touristIds += `${this.newtTableDataSelectArr[i].uuid},`;
      }
      this.js_uuids = touristIds.substring(0, touristIds.length - 1);
    },
    // 新增保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let uuids = ''
          this.selectTableData.forEach((item, index) => {
            if(index == 0) {
              uuids = item.uuid
            } else {
              uuids += ',' + item.uuid
            }
          })

          if(!this.$sishiLength(this.form.js_name)) {
            return this.$message({
              type: 'error',
              message: '名称最大长度为40'
            })
          }

          if(!this.$bashiLength(this.form.js_remark)) {
            return this.$message({
              type: 'error',
              message: '备注最大长度为80'
            })
          }

          var data = {
            name: this.form.js_name,
            demo: this.form.js_remark,
            user_uuids: uuids,
          };
          if (this.form.id !== '') {
            data.uuid = this.form.id;
          }

          this.$axios
            .post(
              this.form.id == '' ? '/p/role/add' : '/p/role/update',
              this.$qs(data)
            )
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: res.data.message,
                  type: 'success',
                });
                  this.selectTableData = []
                this.create();
                this.dialogTableVisible = false;
                this.$nextTick(() => {
                  this.$refs.dataTable.resetFields();
                });
              } else {
                this.$utils.message({
                  type: 'error',
                  message: res.data.message,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    // 查看当前页数
    getNum(data) {
      var num = data / 10,
        pages = '';
      if (num > Math.floor(num)) {
        pages = Math.ceil(num);
      } else {
        pages = num;
      }
      return pages;
    },
  },
};
</script>

<style lang="scss" scoped>
.child-top {
  .input-from {
    float: left;
    min-width: 600px;
    margin-bottom: 0;

    .searchBtn{
      margin-top: 20px;
    }

    & > .el-select {
      width: 150px;
      float: left;
      margin-top: 20px;

      .el-input__inner {
        border-radius: 0;
        border-right: transparent;
      }

      .el-input__inner:focus {
        border-right: 1px solid #409eff;
      }
    }

    .el-select,
    .el-input {
      margin-top: 20px;
    }

    .el-select:nth-child(4) {
      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(5) {
      .el-input__inner {
        border-right: 0;
      }

      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(6) {
      width: 120px;
      margin-left: 20px;

      .el-input__inner {
        border-radius: 0px 4px 4px 0;
      }
    }

    .el-select:nth-child(1) {
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }

    .el-input.el-input--suffix.left-child-input {
      float: left;
      width: 200px;

      .el-input__inner {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .operation-button {
    // float: left;
    margin-bottom: 10px;
  }
}
.ModifyDoalog {
  margin-top: -20px;
  padding: 0 0 0 20px;
  & > p {
    font-size: 20px;
    color: #3b3b3b;
    text-align: center;
    margin-bottom: 20px;
  }
  .el-form {
    padding-right: 30px;
    .form_inRact{
      display: flex;
      margin-bottom: 20px;
      .el-form-item:first-child{
        margin-right: 5%;
      }
    }
  }
}
.table-pagination {
  text-align: right;
  margin-top: 10px;
  /deep/ span {
    float: left;
  }
}
.newTable {
  height: 400px;
  overflow: auto;
  .el-table{
    width: 40%;    
  }
}

/deep/ input::-webkit-input-placeholder {
  color: #999;
}
/deep/ input::-moz-input-placeholder {
  color: #999;
}
/deep/ input::-ms-input-placeholder {
  color: #999;
}

.iconshanchu-moren{
  &:hover{
    color: #f00;
    cursor: pointer;
  }
}

.el-pagination{
  background: #fff;
  padding: 10px;
  margin-top: 0;
}
</style>
